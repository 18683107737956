import { Button, DataTable, DEFAULT_SEARCH_DEBOUNCE_DELAY, ErrorMessage, SearchField } from "@ggl/components"
import { appendQueryString } from "common/utils/appendQueryString"
import { useRouter } from "next/router"
import React, { useCallback, useEffect, useState } from "react"
import Cardholder, { CardholderWithThumbnail } from "../../common/types/api.cardholder.type"
import type { LinkWithName } from "../../common/types/api.link.type"
import type { Results } from "../../common/types/api.search.type"
import { ApiError, get } from "../../common/utils/apiCall"
import { CustomError, ErrorList } from "../../common/utils/errors"
import { getMultipleThumbnails } from "../../common/utils/getThumbnail"
import invariant from "../../common/utils/invariant"
import { captureTelemetry } from "../../common/utils/postHog"
import truncate from "../../common/utils/truncate"
import { selectCanAddCardholderUrl, selectCardholders, selectResponsiveLayout, selectTimestamp, useAppDispatch, useAppSelector } from "../../store"
import styles from "./cardholderSearch.module.scss"
import { selectResults, setResults } from "./cardholderSearch.slice"
import { MAX_SEARCH_QUERY_LENGTH_FOR_ERROR, SEARCH_FIELD_MAX_LENGTH } from "./cardholderSimpleSearch.util"

const DEFAULT_NUM_RESULTS = 25
const LOAD_MORE_INCREMENT = 20

const TRY_AGAIN = "Try another search"
const NETWORK_ISSUES = "Currently experiencing connection issues"
const ERROR_WITH_QUERY = "Error with search query"
const ADD_CARDHOLDER = "Add Cardholder"

const parseNumResults = (numResults: string | null) => {
  const n = parseInt(numResults ?? "")
  return !isNaN(n) ? n : DEFAULT_NUM_RESULTS
}

const NoResultsMessage = (query: string) => (
  <div title={query} style={{ height: 1000 }}>
    No results were found for <em>`{truncate(query, MAX_SEARCH_QUERY_LENGTH_FOR_ERROR)}`</em>
  </div>
)

const CardholderSearch = () => {
  // work out if we have an existing query
  const queryParams = new URLSearchParams(window.location.search)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [query, setQuery] = useState<string>(queryParams.get("q") ?? "")
  const layout = useAppSelector(selectResponsiveLayout)

  const [isLoading, setIsLoading] = React.useState(false)
  const [apiError, setApiError] = useState<string>()
  const [queryValidationError, setQueryValidationError] = useState<string>()
  const cardholderResults = useAppSelector(selectResults)
  const cardholderUrl = useAppSelector(selectCardholders)
  const timestamp = useAppSelector(selectTimestamp)
  const [numResults, setNumResults] = useState(parseNumResults(queryParams.get("top")))

  const [canCreateCardholder, setCanCreateCardholder] = useState(false)
  const canAddCardholderLink = useAppSelector(selectCanAddCardholderUrl)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // When the search input changes state reset num of results and assign value
    setNumResults(DEFAULT_NUM_RESULTS)
    setQuery(e.target.value ?? "")
  }

  const submitHandler = useCallback(
    async (numResults: number) => {
      setIsLoading(true)
      setApiError(undefined)
      setQueryValidationError(undefined)
      const fields = ["id", "href", "firstName", "lastName", "shortName", "cards.number", "division", "description"]
      const requestUrl = appendQueryString(cardholderUrl!, {
        name: query,
        fields: fields.join(","),
        top: numResults,
        sort: "name"
      })

      // special character regex
      const specialCharRegex = /[&|]/
      try {
        let searchResults
        if (specialCharRegex.test(query)) {
          dispatch(setResults([]))
          setQueryValidationError("Name cannot contain special characters")
          throw new CustomError(ErrorList.Unknown)
        } else {
          searchResults = await get<Results<Cardholder>>(requestUrl)
        }
        // checks if search results is a valid set of results
        invariant(searchResults, ErrorList.Unknown)
        if (searchResults.results.length) {
          if (!searchResults) {
            throw new CustomError(ErrorList.Unknown)
          }
          // get the ids from the results so we can load the thumbnails
          const ids: Array<string> = searchResults.results.filter((ch) => !!ch.id).map((ch) => ch.id!)
          // get the thumbnail collection
          const thumbnails = await getMultipleThumbnails(ids)

          // merge the thumb names and search results together
          const merged = searchResults.results.map((ch, i) => Object.assign({}, ch, thumbnails[i]))
          dispatch(setResults(merged))
        } else {
          dispatch(setResults(searchResults.results))
        }
      } catch (err: unknown) {
        if (err instanceof ApiError) {
          setApiError(err.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [cardholderUrl, dispatch, query]
  )

  // This takes in the href and id of the card holder and routes to the single page view
  const routeHandler = (id: string | number) => {
    captureTelemetry("Clicked on a Cardholder")
    router.push({
      pathname: `/people`,
      query: { id: id }
    })
  }

  // Increases the number of cardholders requested in the API call
  const loadMore = (quantity: number) => {
    setNumResults(numResults + quantity)
    submitHandler(numResults + quantity)
  }

  // Update the history which also updates the url in the browser's address bar
  useEffect(() => {
    const q = query == "" ? "?top=" + numResults : "?q=" + query + "&top=" + numResults
    if (window.location.search !== q) {
      const url = `${window.location.pathname}${q}`
      // using next router causes a complete app re-render. Swapping to using replaceState fixes that issues
      // more info here: https://github.com/vercel/next.js/discussions/18072
      window.history.replaceState({ ...window.history.state, as: url, url: url }, "", url)
    }
  }, [numResults, submitHandler, query])

  // Check if operator is privileged to add a new cardholder
  useEffect(() => {
    try {
      invariant(canAddCardholderLink)
      get<Results<LinkWithName>>(`${canAddCardholderLink.href}`).then((response) => {
        setCanCreateCardholder(response.results.length > 0)
      })
    } catch (e: unknown) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAddCardholderLink])

  return (
    <div className={styles.root} style={{ height: "1000px" }}>
      <div className={styles.searchHeader}>
        <form className={styles.form}>
          <SearchField
            id="cardholder_search"
            autoFocus
            isLoading={isLoading}
            value={query}
            className={styles.search}
            type={"search"}
            placeholder="Search"
            onChange={handleSearchChange}
            onSubmit={() => submitHandler(numResults)}
            size="large"
            maxLength={SEARCH_FIELD_MAX_LENGTH}
            debounce={DEFAULT_SEARCH_DEBOUNCE_DELAY}
            autoSubmit
            autoSubmitOnMount
          />
        </form>
        <Button
          color="primary"
          leftIcon="add"
          className={styles.addButton}
          onClick={() => {
            router.push("/people/add")
            captureTelemetry("Clicked (Add Cardholder) Button")
          }}
          disabled={false}
          //disabled={!canCreateCardholder}
          size={layout === "mobile" ? "large" : "medium"}
          fullWidth={layout === "mobile"}
        >
          {ADD_CARDHOLDER}
        </Button>
      </div>
      <div className={styles.panel} style={layout === "mobile" ? { marginTop: "8rem" } : {}}>
        <DataTable<CardholderWithThumbnail>
          data={
            cardholderResults?.map((t: CardholderWithThumbnail) => {
              return {
                thumbnail: t.thumbnail?.concat("&", timestamp), //using timestamp to force browser refresh the image
                ...t
              }
            }) ?? []
          }
          onClickItem={routeHandler}
          onError={queryValidationError ? <ErrorMessage message={queryValidationError} title={ERROR_WITH_QUERY} /> : apiError ? <ErrorMessage message={apiError} title={NETWORK_ISSUES} /> : undefined}
          onNoResults={cardholderResults && !isLoading && query.length > 0 && cardholderResults.length === 0 ? <ErrorMessage title={NoResultsMessage(query)} message={TRY_AGAIN} /> : undefined}
          showAvatar={true}
          className={styles.results}
          columns={{
            firstName: "First Name",
            lastName: "Last Name",
            description: "Description"
          }}
        ></DataTable>
        {cardholderResults && cardholderResults.length >= numResults && (
          <div className={styles.loadButton}>
            <Button
              onClick={() => {
                loadMore(LOAD_MORE_INCREMENT)
                captureTelemetry("Clicked (View More) Cardholders")
              }}
              text="Load More"
              id="load_button"
              loading={isLoading}
              color="primary"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CardholderSearch
